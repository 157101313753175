var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"warehouse.statistics_quantity_products_inventory","resource":"","reloadable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.filterChange},scopedSlots:_vm._u([{key:"_",fn:function(ref){
var index = ref.index;
return [_c('td',[_c('span',{staticClass:"d-flex justify-content-center"},[_vm._v(" "+_vm._s(++index)+" ")])])]}},{key:"quantity_inventory",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{attrs:{"value":+item.quantity_inventory}})],1)]}},{key:"product",fn:function(ref){
var item = ref.item;
return [_c('td',[(item)?_c('SCardProductItems',{attrs:{"item":item.product,"widthAuto":"","resource":"/warehouse/management/products"}}):_vm._e()],1)]}},{key:"boxes",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._l((item.boxes),function(box){return _c('div',{key:box.product_id,staticClass:"d-flex"},[_c('TLink',{staticClass:"ml-2 text-primary",attrs:{"content":box.box_id,"messageOptions":{ bold: true },"to":_vm.lodash.getReferenceLink('box', box.box_id)}}),_c('span',{staticClass:"px-1"},[_vm._v("-")]),_c('TMessage',{attrs:{"content":"Quantity","bold":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(": "+_vm._s(box.quantity_inventory_in_boxes)+" ")]},proxy:true}],null,true)})],1)}),(!item.boxes.length)?_c('TMessageNotFound'):_vm._e()],2)]}},{key:"product-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Product Id","value":_vm.filter.product_id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "product_id", $event)},_vm.filterChange]}})]},proxy:true},{key:"boxes-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"SKU Id","value":_vm.filter.box_id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "box_id", $event)},_vm.filterChange]}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }